import { DefaultTheme, createGlobalStyle } from "styled-components"
import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"
import { createTheme } from "@mui/material/styles"

export const lightTheme = {
  colors: {
    primary: "#001F58",
    primaryLight: "rgba(0, 31, 88,0.2)",
    primaryDark: "#000E27",
    secondary: "#FFC211",
    secondaryLinear: "#FFC211",
    secondaryLight: "rgba(255, 194, 17,0.2)",
    background: "#FFFFFF",
    title: "#001F58",
    titleHighlight: "#FFC211",
    text: "#333",
    lightText: "#999",
    headingColor: "#001F58",
    white: "#FFFFFF",
    primaryButtonText: "#FFFFFF",
    textShadowLight: "rgba(255, 194, 17,0.5)",
    textShadowDark: "#03122E"
  }
}
export const darkTheme: DefaultTheme = {
  colors: {
    primary: "#FFC211",
    primaryFamily: {
      linear: "linear-gradient(270deg, #FFC211 0%, #FFAE11 100%)"
    },
    linearFamily: {
      back: "linear-gradient(270deg, #032156 0%, #011538 97.92%)",
      light:
        "linear-gradient(45deg, rgba(255, 194, 17, 0.30) 0%, rgba(33, 77, 159, 0.30) 97.92%)",
      darkLight: "linear-gradient(59deg, #FFF8E3 0%, #E9F0FD 97.84%)"
    },
    primaryLinearCard: "linear-gradient(-45deg, #FFC21180 0%, #FFC21130 )",
    primaryLinearBG: "linear-gradient(200deg, #FFC21150 0%, #FFC21100 )",
    primaryLight: "rgba(255, 194, 17,0.2)",
    primaryDark: "#E4AA00",
    secondary: "#031942",
    secondaryLinear:
      "linear-gradient(180deg, #001F58 0%, #001F5800 50%,#001F5800 60%,#001F58)",
    secondaryLinearCard: "linear-gradient( 160deg, #03122E, #001F58 70% );",
    secondaryLight: "rgba(0, 31, 88,0.2)",
    dark: "#000C22",

    background: "#03122E",
    title: "#FFFFFF",
    titleHighlight: "#FFC211",
    text: "rgba(255,255,255,0.8)",
    lightText: "rgba(255,255,255,0.5)",
    headingColor: "#FFC211",
    white: "#FFFFFF",
    primaryButtonText: "#03122E",
    textShadowLight: "rgba(255, 194, 17,0.5)",
    textShadowDark: "#03122E",

    boxFill: "#031942",
    backgroundFamily: {
      L2: "#E7E7E7",
      light: "#FFFFFF",
      black: "#03122E"
    },
    textFamily: {
      black: "#121212",
      gray: "#2D2D2D",
      lightGray: "#C4C4C4",
      contrast: "#FFFFFF",
      secondary: "rgba(255, 255, 255, 0.40)",
      disabled: "rgba(255, 255, 255, 0.80)"
    },
    error: "#E1004A",

    transparent: {
      hover: "rgba(255, 255, 255, 0.10)"
    }
  },
  ssm: "max-width:500px",
  sm: "max-width:640px",
  md: "max-width:768px",
  lg: "max-width:1024px",
  xl: "max-width:1280px",
  xxl: "max-width:1400px",
  lgWidth: 1024
}

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: darkTheme.colors.primary,
      dark: darkTheme.colors.primaryDark,
      light: darkTheme.colors.primaryLight
    },
    secondary: {
      main: darkTheme.colors.secondary,
      light: darkTheme.colors.secondaryLight
    }
  }
})

function getRandom(min: any, max: any) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

// Legacy global styles
export const GlobalStyles = createGlobalStyle`
*{
  box-sizing:border-box;
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    color: 123;
    background-color: none;
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar-thumb {
    background: ${themeGet("colors.primary")};
    border-radius: 5px;
  }
  ::-webkit-scrollbar-corner {
    border: none;
  }
  ::selection {
    background: ${themeGet("colors.backgroundFamily.L2")};
    color: #000000;
    -webkit-text-fill-color: initial;
  }
}

:root{
  --maxWidth:1400px;
  --maxW:1040px;
  --paddingX:60px;
  --cardPadding:48px;
  --cardBORA:24px;
  --cardBORAsmall:12px;
  --cardGap:64px;
  --textGap:16px;
  --titleGap:24px;
  --gapS:12px;
  --sectionBORA: 48px;
  --BORA: 24px;
  --h1:48px;
  --h2:36px;
  --h3:24px;
  --h3New:24px;
  --h4:20px;
  --h5:16px;
  --h6:16px;
  --p:16px;
  --small:12px;
  @media (max-width:1024px) {
  --cardPadding:48px;
  --cardBORA:24px;
  --cardBORAsmall:12px;
  --cardGap:48px;
  --h1:40px;
  --h2:32px;
  --h3:24px;
  --h4:20px;
  }
  @media (max-width:768px) {
    --paddingX:30px;
    --cardPadding:24px 12px;
    --cardBORA:8px;
  --cardBORAsmall:6px;

    --cardGap:32px;
    --textGap:8px;
    --sectionBORA: 24px;
    --BORA: 12px;
    --h1:32px;
    --h2:28px;
    --h3:20px;
    --h3New:18px;
    --h4:18px;
  }
  @media (max-width:640px) {
    --cardGap:16px;
    --h1:28px;
    --h2:28px;
    --h3:18px;
    --h4:18px;
    --small:11px;
  }
  
}

  html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
    scroll-behavior: smooth;
  }
  body {
    background: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.text};
    transition: all 0.50s linear;
    letter-spacing: 0.01em;
    margin: 0;
    padding: 0;
    font-family:'__Roboto_0f2970', '__Roboto_Fallback_0f2970', '__Noto_Sans_TC_Fallback_46da60', '__Noto_Sans_TC_Fallback_Fallback_46da60';
    -webkit-tap-highlight-color: transparent;
  }
  h1,h2,h3,h4,h5,h6,p{margin: 0;}

  p{font-size:var(--p); font-weight:500;line-height:1.8;letter-spacing:0.02em;color:${({
    theme
  }) => theme.colors.text};}
  small{font-size:var(--small); font-weight:500;line-height:2;color:${({
    theme
  }) => theme.colors.lightText};}
  a {
    text-decoration: none;
  }
  section {
    position: relative;
  }
  .reusecore__button {
    font-weight: 500;
  }
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    list-style-type: none;
  }
  .ellipsis2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

  .text-glow {
    text-shadow: 0 0 20px ${({ theme }) => theme.colors.primaryLight},
    0 0 60px ${({ theme }) => theme.colors.textShadowLight};
    @media (max-width:768px){
      text-shadow: 0 0 10px ${({ theme }) => theme.colors.primaryLight},
    0 0 30px ${({ theme }) => theme.colors.textShadowLight};
    }
    @media (max-width:640px){
      text-shadow: 0 0 5px ${({ theme }) => theme.colors.primaryLight},
    0 0 10px ${({ theme }) => theme.colors.textShadowLight};
    }
  }
  .text-gradient {
    background: linear-gradient(to right,${({ theme }) =>
      theme.colors.title}, ${({ theme }) => theme.colors.titleHighlight});
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
  }

  .outlineBox {
    border-radius: 24px;
    border: 1px solid ${themeGet("colors.primaryLight")};
    box-shadow: 0px 4px 12px rgba(245, 188, 33, 0.1);
    transition: all 0.3s ease;
    &:hover {
      box-shadow: 0px 8px 48px rgba(245, 188, 33, 0.3),
        inset 0px 8px 16px rgba(245, 188, 33, 0.5);
      }
  }

  .gradientBG{
    background: linear-gradient(
      139deg,
      rgba(255, 194, 17, 0.3) 0%,
      rgba(255, 194, 17, 0.15) 30%,
      rgba(255, 194, 17, 0) 90%
    );
  }

  @media only screen and (max-width: 768px) {

    .container {
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  @media only screen and (max-width: 650px) {
    .container {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  @media only screen and (width: 320px) {
    .container {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  @keyframes spin {
    100% {
      transform: rotate(360deg) ;
    }
  }
  @keyframes updown {
    50% {
      transform: translate(0px, 20px) scale(1.02);
    }
  }
  @keyframes twingle {
    0% {
      transform: rotate(0deg) scale(0.8);
      opacity: 0;
    }
    50% {
      transform: rotate(${getRandom(30, 360)}deg) scale(1.2);
      opacity: 1;
    }
    100% {
      transform: rotate(0deg) scale(0.8);opacity: 0;
    }
  }
  @keyframes Appear {
    40% {
      transform: translate(0px, 12px) scale(1);
      opacity: 1;
    }
    50% {
      transform: translate(0px, 20px) scale(0.3);
      opacity: 0;
    }
    60% {
      transform: translate(0px, -12px) scale(1);
      opacity: 1;
    }
  }
  @keyframes LeftRight {
    50%{
      transform: translate( -100%,0);
    }
  }
  @keyframes RightLeft {
    50%{
      transform: translate(100%,0);
    }
  }

  @keyframes zoom {
    100%{
      transform: scale(1);
      opacity: 1;
    }
  }
`

export const AppWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .sticky-active {
    .navbar {
      padding: 20px 0;
      background-color: ${themeGet("colors.white", "#ffffff")};
      box-shadow: 0px 3px 8px 0px rgba(43, 83, 135, 0.08);
      // @media only screen and (max-width: 1366px) {
      //   padding: 15px 0 16px;
      // }
      .main-logo {
        display: none;
      }
      .logo-alt {
        display: block;
      }
      // .mobile-menu {
      //   top: 72px;
      // }
      ul {
        li {
          a {
            color: ${themeGet("colors.secondary", "#000")};
            font-size: 16px;
            font-weight: 400;
            transition: all 0.3s ease;
            &:hover {
              color: ${themeGet("colors.primary", "#d1397c")};
            }
          }
          &.is-current {
            a {
              color: ${themeGet("colors.primary", "#d1397c")};
            }
          }
        }
      }
      .reusecore__button {
        &.menubar {
          color: ${themeGet("colors.secondary", "#000")};
        }
        &.text {
          color: ${themeGet("colors.secondary", "#000")};
          .btn-icon {
            svg {
              stroke: ${themeGet("colors.secondary", "#000")};
            }
          }
          @media only screen and (max-width: 991px) {
          }
        }
      }
    }
  }
`

export const ContentWrapper = styled.div`
  flex: 1 0 auto;
  width: 100%;
  overflow: hidden;
`

export const SectionHeader = styled.header`
  max-width: 352px;
  width: 100%;
  margin: 0 auto 58px;
  text-align: center;
  @media only screen and (max-width: 991px) {
    margin-bottom: 50px;
  }
  h5 {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 12px;
    letter-spacing: 1.5px;
    color: ${themeGet("colors.primary", "#2563FF")};
    text-transform: uppercase;
    @media only screen and (max-width: 991px) {
      font-size: 13px;
      margin-bottom: 10px;
    }
  }
  h2 {
    font-size: 30px;
    line-height: 36px;
    font-weight: 700;
    color: ${themeGet("colors.headingColor", "#0F2137")};
    margin: 0;
    letter-spacing: -1px;
    @media only screen and (max-width: 1366px) {
      font-size: 28px;
      letter-spacing: -0.7px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 26px;
      line-height: 38px;
      letter-spacing: -0.5px;
    }
  }
`
