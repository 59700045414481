import { useEffect } from "react"
import { useRouter } from "next/router"
import Head from "next/head"
import Script from "next/script"
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles"
import { appWithTranslation } from "next-i18next"
import { ThemeProvider } from "styled-components"
import { GlobalStyles, darkTheme, muiTheme } from "@/common/theme"
import { DefaultSeo } from "next-seo"
import { Roboto, Noto_Sans_TC } from "next/font/google"

import "@/css/style.css"

const roboto = Roboto({
  subsets: ["latin"],
  weight: ["100", "300", "400", "500", "700", "900"]
})
const noto_sans_TC = Noto_Sans_TC({
  subsets: ["latin"],
  weight: ["100", "300", "400", "500", "700", "900"]
})

function CustomApp({ Component, pageProps }: any) {
  const router = useRouter()
  const { locale } = router

  const Metadata = {
    title: "KryptoGO - Establishing Secure Spaces in Web3 Finance",
    description:
      "KryptoGO is on a mission to build a secure and transparent environment in Web3 Finance by providing competitive products including one-stop compliance solution, crypto asset management and a non-custodial DeFi wallet.",
    keywords: "Blockchain, KYC, AML, Wallet, Dashboard, NFT, Crypto",
    image:
      "https://storage.googleapis.com/kryptogo-official-website-public/og-logo.webp",
    themeColor: "#FFC211",
    url: "https://www.kryptogo.com/"
  }

  useEffect(() => {
    setTimeout(() => {
      import("react-facebook-pixel")
        .then(x => x.default)
        .then(ReactPixel => {
          ReactPixel.init("597520178716785") // facebookPixelId
          ReactPixel.pageView()

          router.events.on("routeChangeComplete", () => {
            ReactPixel.pageView()
          })
        })
    }, 3500)
  }, [router.events])

  let host = ""
  if (typeof window !== "undefined") {
    host = window.location.hostname
  }
  return (
    <>
      <Head>
        <link rel="preconnect" href="https://cdn.jsdelivr.net" />
      </Head>
      <Script
        strategy="afterInteractive"
        src="https://cdn.jsdelivr.net/gh/google/code-prettify@master/loader/run_prettify.js"
      ></Script>
      {host === "www.kryptogo.com" && (
        <Script
          strategy="afterInteractive"
          id="google-tag"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-WHH6Q28');`
          }}
        />
      )}

      <main
        className={
          locale === "tw" || locale === "cn"
            ? noto_sans_TC.className
            : roboto.className
        }
      >
        <MuiThemeProvider theme={muiTheme}>
          <ThemeProvider theme={darkTheme}>
            <GlobalStyles />
            <DefaultSeo
              title={Metadata.title}
              description={Metadata.description}
              canonical={Metadata.url}
              languageAlternates={[
                { hrefLang: "en-US", href: "https://www.kryptogo.com/" },
                { hrefLang: "zh-TW", href: "https://www.kryptogo.com/tw" },
                { hrefLang: "zh-CN", href: "https://www.kryptogo.com/cn" },
                { hrefLang: "ja-JP", href: "https://www.kryptogo.com/ja" }
              ]}
              openGraph={{
                url: Metadata.url,
                title: Metadata.title,
                description: Metadata.description,
                images: [
                  {
                    url: Metadata.image,
                    width: 800,
                    height: 600,
                    alt: "Og Image Alt"
                  }
                ],
                site_name: "KryptoGO"
              }}
              twitter={{
                handle: "@kryptogo_",
                site: "KryptoGO",
                cardType: Metadata.image
              }}
            />
            <Component {...pageProps} />
          </ThemeProvider>
        </MuiThemeProvider>
      </main>
    </>
  )
}

export default appWithTranslation(CustomApp)
